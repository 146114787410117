import APIService from './APIService';

export default {
  getAgenteSelected(id) {
    return APIService.apiCall().get(`agentes/${id}`);
  },

  save(postData) {
    return APIService.apiCall().post('/agentes/save', JSON.stringify(postData));
  }
};
